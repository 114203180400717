// persistConfig.js

import storage from 'redux-persist/lib/storage';

const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['auth'],
    // Add any other configuration options here if necessary
};

export default persistConfig;
